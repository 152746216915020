import styles from '@styles/components/Footer.module.scss';

type Props = {
  wrapperStyle?: string,
  textStyle?: string,
  onPress?: Function,
  menus?: {
    title?: string,
  }[],
};

export default function FooterNavigationRows(props: Props) {
  const { wrapperStyle, textStyle, menus } = props;
  return (
    <div className={`flex flex-row ${wrapperStyle}`}>
      {menus.map((item, index) => {
        return (
          <div className="flex flex-row" key={index}>
            {index !== 0 && (
              <div className={`${styles.textFooter} lg:mx-12px 2xl:mx-24px`}>
                |
              </div>
            )}
            <a href={item.url}>
              <div className={`${styles.textFooter} ${textStyle}`}>
                {item.title}
              </div>
            </a>
          </div>
        );
      })}
    </div>
  );
}
